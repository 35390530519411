var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Rapport aantallen OHW")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            disabled: _vm.isLoading,
                            href: "#" + organization.id
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "elementPanel elementPanel--spaced" },
                [
                  _vm.activeOrganization
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.specialFilters,
                                  "item-value": "value",
                                  "item-text": "name",
                                  label: "Specials"
                                },
                                model: {
                                  value: _vm.selectedSpecialFilter,
                                  callback: function($$v) {
                                    _vm.selectedSpecialFilter = $$v
                                  },
                                  expression: "selectedSpecialFilter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportDepartmentFilter", {
                                attrs: {
                                  label: "Afdelingen",
                                  placeholder: "",
                                  dmz: _vm.activeOrganization,
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.selectedDepartments,
                                  callback: function($$v) {
                                    _vm.selectedDepartments = $$v
                                  },
                                  expression: "selectedDepartments"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportTypeFilter", {
                                attrs: {
                                  label: "Rapporttype",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true,
                                  disabled: _vm.currentTab === "ves"
                                },
                                model: {
                                  value: _vm.selectedTypes,
                                  callback: function($$v) {
                                    _vm.selectedTypes = $$v
                                  },
                                  expression: "selectedTypes"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "custom-filter custom-filter--autocomplete",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("ReportTagFilter", {
                                attrs: {
                                  label: "Tags",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.selectedTags,
                                  callback: function($$v) {
                                    _vm.selectedTags = $$v
                                  },
                                  expression: "selectedTags"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.activeOrganization
                            ? _c("RapportAantallenTabs", {
                                on: { tabChange: _vm.handleTabChanged },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var activeTab = ref.activeTab
                                        var tabs = ref.tabs
                                        return [
                                          _c("RapportAantallenPerStatus", {
                                            attrs: {
                                              "active-tab": activeTab,
                                              filters: _vm.parsedFilters,
                                              organization:
                                                _vm.activeOrganization,
                                              tabs: tabs
                                            },
                                            on: { "is-loading": _vm.disableTab }
                                          }),
                                          _c("RapportAantallenPerType", {
                                            attrs: {
                                              "active-tab": activeTab,
                                              filters: _vm.parsedFilters,
                                              organization:
                                                _vm.activeOrganization,
                                              tabs: tabs
                                            },
                                            on: { "is-loading": _vm.disableTab }
                                          }),
                                          _c(
                                            "RapportAantallenPerTypeRegulier",
                                            {
                                              attrs: {
                                                "active-tab": activeTab,
                                                filters: _vm.parsedFilters,
                                                organization:
                                                  _vm.activeOrganization,
                                                tabs: tabs
                                              },
                                              on: {
                                                "is-loading": _vm.disableTab
                                              }
                                            }
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1587606727
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }