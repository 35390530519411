import { Component, Vue } from 'vue-property-decorator';
import { Organization } from '@/models/Organization';
import { cloneDeep } from 'lodash';
import ReportDepartmentFilter from '@/components/filters/report-department-filter/ReportDepartmentFilter.vue';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';
import ReportTagFilter from '@/components/filters/report-tag-filter/ReportTagFilter.vue';
import { ReportTypes } from '@/support/ReportTypes';

@Component<RapportAantallenOhw>({
  components: {
    ReportTypeFilter,
    ReportDepartmentFilter,
    ReportTagFilter,
    RapportAantallenPerType: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenPerType/RapportAantallenPerType.vue'),
    RapportAantallenPerTypeRegulier: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenPerTypeRegulier/RapportAantallenPerTypeRegulier.vue'),
    RapportAantallenPerStatus: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenPerStatus/RapportAantallenPerStatus.vue'),
    RapportAantallenTabs: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenTabs/RapportAantallenTabs.vue'),
  },
})
export default class RapportAantallenOhw extends Vue {
  public $pageTitle = 'Rapport aantallen OHW';

  protected isLoading = true;

  protected specialFilters = [
    {
      name: 'Alle dossiers',
      value: null,
    },
    {
      name: 'Alleen specials',
      value: true,
    },
    {
      name: 'Zonder specials',
      value: false,
    },
  ];

  protected selectedSpecialFilter = null;

  protected selectedDepartments: string[] = [];

  protected selectedTypes: string[] = [];

  protected selectedTags: string[] = [];

  protected filters: ReportAantallenFilter = {};

  protected organizations: Organization[] | null = null;

  protected activeOrganization = '';

  protected currentTab = 'regulier';

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    await this.getOrganizations();
    this.isLoading = false;
  }

  protected handleTabChanged(tab: string): void {
    this.currentTab = tab;
  }

  protected async getOrganizations(): Promise<void> {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .getAllExperts();
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
    }
  }

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapport aantallen OHW' },
        ],
      });
  }

  protected disableTab(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  protected get parsedFilters() {
    const filters: ReportAantallenFilter = {
      departments: this.selectedDepartments,
      types: this.selectedTypes,
      tags: this.selectedTags,
      special: this.selectedSpecialFilter,
      exclude_types: this.currentTab === 'regulier' ? [ReportTypes.VES_NULMETING] : [],
    };

    if (this.currentTab === 'ves') {
      this.selectedTypes = [];
      filters.types = [ReportTypes.VES_NULMETING];
    }

    return filters;
  }
}

export type ReportCountTotalResponse = { [key: string]: ReportCountTotal[] }

export type ReportCountTotal = {
  [key in ReportCountTotalEnum]: number;
};

export enum ReportCountTotalEnum {
  EXTERNAL = 'external',
  PLANNED = 'planned',
  REPLANNED = 'replanned',
  TODO = 'todo',
  TOTAL = 'total',
}

export interface ReportAantallenFilter {
  types?: string[];
  departments?: string[];
  tags?: string[];
  exclude_types?: string[];
  special?: any;
}
